export const AppAccess = {
  "admin": "/10/",
  "administrareinstitutie": "/11/",
  "persoane": "/13/",
  "adrese": "/14/",
  "documente": "/15/",
  "buget": "/16/",
  "contab": "/17/",
  "inventare": "/19/",
  "raportarifinanciare": "/21/",
  "impotax": "/22/",
  "incasari": "/23/",
  "achizitii": "/24/",
  "clienti": "/50/",
  "resum": "/51/",
  "salarii": "/52/",
  "asisoc": "/53/",
  "agroregis": "/54/",
  "contr": "/56/",
  "nomenclator": "/57/",
  "deploy": "/58/",
  "conversiidate": "/59/",
  "invatamant": "/60/",
  "cantina": "/61/",
  "investitii": "/62/",
  "dev": "/63/",
  "user": "/64/"
}