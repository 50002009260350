import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { UserService, AppSettings, States, ConfigService } from 'core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
	constructor(private router: Router, private user: UserService,
		private config: ConfigService, private settings: AppSettings) { }

	canActivate(
		next: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.user.isAuthenticated) {
			console.warn(`Unauthorized, login to continue!`);
			return this.router.parseUrl('login');
		}
		return true
	}

	canActivateChild(
		next: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.user.nivelAcces.then(nivel => {
			if (nivel[next.data.access] > 0) {
				// if (!this.settings.loaded) { return this.settings.getSettings().then(() => { return true }) }
				return true
			} else {
				const url = next.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
				console.warn(`Access denied to "${url}"!`);
				return this.router.parseUrl(`${States.admin.stop}/${window.btoa(url)}/${encodeURIComponent(next.data.title)}`);
			}
		})
	}
}


@Injectable({ providedIn: 'root' })
export class HomeSettingsGuard {
	constructor(public router: Router, private settings: AppSettings) { }

	canActivate(
		next: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// if (!this.settings.loaded) { return this.settings.getSettings().then(() => { return true }) }
		return true
	}
}

@Injectable({ providedIn: 'root' })
export class AppGuard {
	constructor(public router: Router, private user: UserService, private http: HttpClient) { }

	canActivate(
		next: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.user.isAuthenticated) { return true }
		console.warn(`app guard, `, this.user.isAuthenticated);
		return this.http.get('/sigmaInit').pipe(
			map((response: any) => { console.warn('sigmainit', response); return response['mentenance'] ? this.router.createUrlTree(['login']) : true }),
			catchError(() => of(this.router.createUrlTree(['login'])))
		);
	}
}