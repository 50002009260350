<ng-container *ngFor="let btn of buttons">
    <!--divider-->
    <ng-container *ngIf="btn.type === 'div'"><ng-content></ng-content></ng-container>

    <!--button-->
    <span tabindex="0" class="d-inline-block" [ngbTooltip]="tooltip" (mouseover)="tooltip = btn.tooltip"
        *ngIf="btn.type === 'cmd' && !btn.btns && (!btn.splitCaret || (btn.splitCaret && (!btn.btns || (btn.btns && !btn.btns.length)))) && isVisible(btn)">
        <button type="button" class="btn ms-2" [ngClass]="controlSize('btn', btn)"
            [disabled]="isDisabled(btn)" (click)="onClick(btn)">
            <i class="far fa-1x" [ngClass]="btn.icon || 'fa-file-o'"></i> {{btn.caption}}
        </button>
    </span>

    <!--dropdown split caret-->
    <div class="btn-group ms-2"
        *ngIf="btn.type === 'cmd' && btn.splitCaret && btn.btns && btn.btns.length > 0 && isVisible(btn)">
        <span class="btn-group" [ngClass]="controlSize('btn-group')" [ngbTooltip]="tooltip" (mouseover)="tooltip = btn.tooltip">
            <button type="button" class="btn" [ngClass]="controlSize('btn', btn)"
                [disabled]="isDisabled(btn)" (click)="onClick(btn)" [attr.title]="btn.tooltip">
                <i class="far fa-1x" [ngClass]="btn.icon"></i> {{btn.caption}}
            </button>
        </span>
        <div class="btn-group" role="group" [ngClass]="controlSize('btn-group')"
            *ngIf="btn.btns && btn.btns.length > 0"
            display="dynamic" placement="bottom-right" ngbDropdown>

            <button type="button" class="btn dropdown-toggle-split" [ngClass]="btn.class || 'btn-primary'" ngbDropdownToggle></button>
            <div ngbDropdownMenu>
                <span *ngFor="let subcmd of btn.btns" [ngbTooltip]="tooltip" (mouseover)="tooltip = btn.tooltip" placement="start">
                    <button [disabled]="isDisabled(subcmd)" (click)="onClick(subcmd)" ngbDropdownItem>
                        <i class="far fa-fw fa-1x" [ngClass]="subcmd.icon"></i> {{subcmd.caption}}
                    </button>
                </span>
            </div>
        </div>
    </div>

    <!--dropdown caret-->
    <div class="btn-group ms-2" role="group" [ngClass]="controlSize('btn-group')"
        *ngIf="btn.type === 'cmd' && !btn.splitCaret && btn.btns && btn.btns.length > 0 && isVisible(btn)"
        display="dynamic" placement="bottom-right" ngbDropdown>

        <span class="btn-group" [ngClass]="controlSize('btn-group')" [ngbTooltip]="tooltip" (mouseover)="tooltip = btn.tooltip">
            <button type="button" class="btn" [ngClass]="btn.class || 'btn-primary'" [disabled]="isDisabled(btn)"
                [attr.title]="btn.tooltip" placement="bottom" triggers="mouseenter" ngbDropdownToggle>
                <i class="far fa-1x" [ngClass]="btn.icon"></i>
                <span class="hidden-xs">&nbsp;{{btn.caption}}</span>
            </button>
        </span>
        <div ngbDropdownMenu>
            <span *ngFor="let subcmd of btn.btns" [ngbTooltip]="tooltip" (mouseover)="tooltip = btn.tooltip" placement="start">
                <button ngbDropdownItem [disabled]="isDisabled(subcmd)" (click)="onClick(subcmd)">
                    <i class="far fa-fw fa-1x" [ngClass]="subcmd.icon"></i>&nbsp;{{subcmd.caption}}
                </button>
            </span>
        </div>
    </div>
</ng-container>