import { Component, OnInit } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'route-controls-container', templateUrl: './controls.offcanvas.html',
	host: { class: "d-flex flex-column", style: 'height:100%' }, standalone: false
})
export class ControlsContainer implements OnInit {
	constructor(private activeOffcanvas: NgbActiveOffcanvas) { }

	ngOnInit() { }
	close() { this.activeOffcanvas.close() }
}