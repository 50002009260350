<nav class="navbar sticky-top navbar-expand-lg py-1" style="z-index:999 !important;"
    [ngClass]="[style.css.controlsType, style.css.controlsBg, style.css.controlsPosition]">
    <div class="container-fluid">
        <form class="form-inline w-xs-80 me-auto" role="search"
            (submit)="onSearch($event)" *ngIf="!isSearchHidden">
            <div class="input-group">
                <!--ng-model-options="{ debounce: {'default': 500} }"-->
                <input type="text" class="form-control" [ngClass]="controlSize('form-control')"
                    [placeholder]="searchPlaceholder || 'Cautati...'" autocomplete="off"
                    [(ngModel)]="controls.searchText" name="search"
                    (keypress)="onKeyPress($event)" />
                <button type="submit" class="btn btn-primary" [ngClass]="controlSize('btn')">
                    <i class="far fa-1x" [ngClass]="searchIcon || 'fa-search'"></i>
                </button>
            </div>
        </form>

        <button type="button" class="btn btn-primary" *ngIf="style.isMobile;else normalBtn"
            [ngClass]="controlSize('btn')" (click)="openBtnMenu()">
            <i class="fa fa-bars"></i>
        </button>

        <!-- <ng-template #tooltipContent let-msg="msg">{{msg}}</ng-template> -->
        <ng-template #normalBtn>
            <route-controls-btn class="text-end"><i class="far fa-minus fa-rotate-90"></i></route-controls-btn>
        </ng-template>
    </div>
</nav>